<template>
  <b-card title="Items Table">
    <div class="custom-search d-flex justify-content-end justify-content-sm-start mb-2 mb-sm-0">
      <div class="d-flex align-items-center">
        <b-button v-if="!removed" id="show-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="float-right"
                  variant="primary" :to="{ name: 'create-item' }">Add Item
        </b-button>
      </div>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <v-select id="item_id" :loading="isSelectLoading" class="custom-select-style" v-model="params.product_id"
                :options="productOptions" placeholder="Product" :reduce="option => option.id" label="name"/>

      <b-form-group>
        <div class="d-flex align-items-center">
          <!-- <label class="mr-1">Search</label> -->
          <b-form-input
              v-model="params.search"
              placeholder="Search"
              type="text"
              class="d-inline-block"
              debounce="1000"/>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table mode="remote" :pagination-options="{
      enabled: true,
    }" :totalRows="totalRecords" :rows="rows" :columns="columns" @on-page-change="onPageChange"
                    @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" :isLoading.sync="isLoading">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <b-button variant="secondary" :to="{ name: 'update-item', params: {id: props.row.id}}">
            <feather-icon icon="SettingsIcon"/>
          </b-button>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'active'">
          <b-form-checkbox @change="event => changeStatus(props.row.id)" :checked="props.row.status == 'Active'"
                           class="z-index-0" switch inline> </b-form-checkbox>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{ isOPen ? "Loading..." : "This will show up when there are no rows" }}
        </p>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import ItemDescriptionService from "@/services/ItemDescriptionService";
import ProductService from "@/services/ProductService";
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  name: 'ItemList',
  mixins: [],
  components: {
    VueGoodTable, BAvatar, BBadge,
    BPagination, BFormGroup, BFormInput,
    BFormSelect, Prism, BDropdownItem,
    BDropdown, BCard, BButton,
    vSelect, BFormCheckbox
  },
  directives: {
    Ripple,
  },
  props: {
    removed: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      params: {
        page: undefined,
        per_page: undefined,
        sort_keys: undefined,
        sort_dir: undefined,
        search: undefined,
        product_id: undefined,
      },
      columns: [
        {label: 'Item ID', field: 'id'},
        {label: 'Item code', field: 'item.reference_code.name'},
        {label: 'Product / Category', field: 'item.product_category.name'},
        {label: 'Item Name', field: 'item.name'},
        {label: 'Description', field: 'description.content'},
        {label: 'Low Price', field: 'low_price'},
        {label: 'High Price', field: 'high_price'},
        {label: 'Created At', field: 'created_at'},
      ],
      rows: [],
      productOptions: [],
      isSelectLoading: false,
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      ItemDescriptionService: new ItemDescriptionService(),
      ProductService: new ProductService(),
      modalOptions: undefined,
      userData: JSON.parse(localStorage.getItem("userData"))
    }
  },
  watch: {
    params: {
      handler: function () {
        this.getItemsDescription();
      },
      deep: true
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    client(rowObj) {
      return `${rowObj.client.first_name} ${rowObj.client.last_name}`;
    },
    onPerPageChange(page) {
      this.params.page = page.currentPage;
      this.params.per_page = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.per_page = active.currentPerPage;
    },
    onSortChange(params) {
      const SortKey = typeof params[0].field === 'function' ? "client" : params[0].field;
      this.params.sort_keys = [SortKey];
      this.params.sort_dir = [params[0].type];
    },
    getProduct() {
      this.isSelectLoading = true;
      this.ProductService.index().then(res => {
        this.productOptions = res.data;
      }).catch(err => {
      })
          .finally(() => {
            this.isSelectLoading = false;
          });
    },
    getItemsDescription() {
      this.isLoading = true;
      this.ItemDescriptionService.index({...this.params}).then(res => {
        this.rows = res.data;
        this.totalRecords = res.total;
      }).catch(err => {
      })
          .finally(() => {
            this.isLoading = false;
            this.isOPen = false;
          });
    },
    changeStatus(id) {
      this.isLoading = true;
      var value = !(this.rows.find(el => el.id == id).status == "Active");
      this.ItemDescriptionService.changeStatus(id, value).then(res => {
        this.rows.find(el => el.id == id).status = value;
      }).catch(err => {
      })
          .finally(() => {
            this.isLoading = false;
            this.isOPen = false;
          });
    },
  },
  mounted() {
    if (this.userData.roles[0].name === 'Admin' || this.userData.roles[0].name === 'Super_Admin') {
      this.columns.push(
          {label: 'Active', field: 'active', sortable: false},
          {label: 'Edit', field: 'action', sortable: false}
      )
    }
  },
  created() {
    if (this.$store.state.items.table) {
      this.params = this.$store.state.items.table
    } else {
      this.getItemsDescription()
    }
    this.getProduct();
    if (this.$store.state.items.table)
      this.params = this.$store.state.items.table
  },
  beforeDestroy: function () {
    this.$store.commit('items/setTable', this.params)
  }
}
</script>
<style>
.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}

@media (max-width: 576px) {

  th,
  td {
    /* font size */
    font-size: 0.857rem;
    font-weight: 400;
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
}

.custom-select-style {
  min-width: 250px;
  margin-right: 6px;
}
</style>
