<template>
    <div class="">
        <item-list />
    </div>
</template>
  
<script>
import ItemList from "@/views/components/item/ItemList";
import '@core/scss/vue/libs/vue-good-table.scss';
export default {
    name: "Index",
    components: {
        ItemList
    }
}
</script>
  
<style scoped lang="scss">

</style>
  